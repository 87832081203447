<template>
  <div class="error-logo">
    <img
      :src="logo"
      alt="forexlive.com"
      title="Forex News, Technical Analysis & Trading Tools"
      class="error-logo__image"
    />
    <div class="error-logo__text__wrapper">
      <p class="error-logo__text title">WE'RE SORRY</p>
      <p class="error-logo__text title">
        THIS PAGE HAD A HARD
        <a-visibility show :on="[$breakpoint.mobile]"> <br /> </a-visibility>
        LANDING
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AErrorLogo',
  computed: {
    logo() {
      return this.$helper.getLinkToFlBucketFile('fl-logo-dark.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.error-logo {
  display: flex;
  flex-direction: column;

  &__image {
    display: block;
    width: 150px;
    margin: 0 auto 30px auto;
  }

  &__text {
    text-align: center;

    @include mobile {
      font-size: 14px;
    }
  }
}
</style>
